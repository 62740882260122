import React from 'react'
import { graphql, Link } from "gatsby"
import { Col, Container, Row } from 'react-bootstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"
import SpotlightArticle from '../components/SpotlightArticle'

export default function HolidayList(props) {
    // console.log(props)
    return (
        <Layout pageInfo={{ pageName: "Holiday List" }}>
            <SEO title="Holiday List" />
            {props.data.allIntranetPages.edges.map(calender => (
                <SpotlightArticle tags={calender.node.title} title={calender.node.excerpt} height={30} bgColor={`#36d5d4`} />
            ))}
            <div className="calender">
                <Container>
                    <Row>
                        <Col>
                            {props.data.allIntranetPages.edges.map(calender => {
                                return(
                                    <>
                                        <small className="breadcrumb">
                                            <Link to="/">Home</Link> <div className="separator">/</div> <Link to={`/other-essentials`}>Other Essentials</Link> <div className="separator">/</div> {calender.node.title}
                                        </small>
                                        <div className="calender-img" key={calender.node.id} dangerouslySetInnerHTML={{__html:calender.node.content}} />
                                    </>
                                )
                            })}
                            {/* <div className="calender-img"><img src={holidaycalender} alt="" /></div> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}
export const query = graphql`
    query HolidayCalender {
        allIntranetPages(filter: {slug: {eq: "holiday-list"}}) {
            edges {
              node {
                id
                id__normalized
                title
                excerpt
                content
              }
            }
        }
    }
`